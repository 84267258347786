<template>
  <div>
    <p class="text-center text-body-1 text-sm-h6 mt-10 mb-1">Contact our {{ $store.state.paymentDetails.Branch }} office for support.</p>
    <p class="text-center text-body-1 text-sm-h6 my-0">Tel: <a id="phone-number" :href="`tel:${$store.state.paymentDetails.BranchPhone}`">{{ $store.state.paymentDetails.BranchPhone }}</a></p>

    
  </div>
</template>

<script>
export default {
  name: 'contactDetails'
}
</script>

<style scoped>
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: underline;
}
</style>