<template>
  <div>
    <p
      v-if="$store.state.paymentDetails.Request.Frequency === 'OneOff'"
      class="text-body-1 text-sm-h6 my-0 text-center"
    >
      You can also pay by direct
      <span class="font-weight-black">bank transfer</span> to:
    </p>
    <p v-else class="text-body-1 text-sm-h6 my-0 text-center">
      You can also set up a standing order manually via your bank using the
      following details:
    </p>
    <v-card outlined class="mt-3">
      <v-card-text>
        <v-simple-table>
          <template v-slot:default>
            <tbody class="">
              <tr tabindex="0">
                <td class="text-caption">Amount:</td>
                <td
                  class="
                    font-weight-black
                    grey--text
                    text--darken-1 text-caption
                  "
                >
                  GBP {{ $store.state.paymentDetailsToSubmit.total }}
                </td>
                <td></td>
              </tr>
              <tr tabindex="0">
                <td class="text-caption">Account name:</td>
                <td
                  class="
                    font-weight-black
                    grey--text
                    text--darken-1 text-caption
                  "
                >
                  {{ $store.state.paymentDetails.BankAccountName }}
                </td>
                <td>
                  <v-btn
                    @click="copyAccountName"
                    icon
                    aria-label="Copy to clipboard"
                  >
                    <v-icon color="grey" v-if="accountNameCopied"
                      >mdi-check</v-icon
                    >
                    <v-icon color="grey" v-else>mdi-content-copy</v-icon>
                  </v-btn>
                </td>
              </tr>

              <tr tabindex="0">
                <td class="text-caption">Account no.:</td>
                <td
                  class="
                    font-weight-black
                    grey--text
                    text--darken-1 text-caption
                  "
                >
                  {{ $store.state.paymentDetails.BankAccountAccountNumber }}
                </td>
                <td>
                  <v-btn
                    @click="copyAccountNo"
                    icon
                    aria-label="Copy to clipboard"
                  >
                    <v-icon color="grey" v-if="accountNoCopied"
                      >mdi-check</v-icon
                    >
                    <v-icon color="grey" v-else>mdi-content-copy</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr tabindex="0">
                <td class="text-caption">Sort code:</td>
                <td
                  class="
                    font-weight-black
                    grey--text
                    text--darken-1 text-caption
                  "
                >
                  {{ $store.state.paymentDetails.BankAccountSortCode }}
                </td>
                <td>
                  <v-btn
                    @click="copySortCode"
                    icon
                    aria-label="Copy to clipboard"
                  >
                    <v-icon color="grey" v-if="sortCodeCopied"
                      >mdi-check</v-icon
                    >
                    <v-icon color="grey" v-else>mdi-content-copy</v-icon>
                  </v-btn>
                </td>
              </tr>
              <tr tabindex="0">
                <td class="text-caption">Reference</td>
                <td
                  ref="requestID"
                  class="
                    font-weight-black
                    grey--text
                    text--darken-1 text-caption
                  "
                >
                  {{ $store.state.requestID }}
                </td>
                <td>
                  <v-btn
                    @click="copyRequestID"
                    icon
                    aria-label="Copy to clipboard"
                  >
                    <v-icon color="grey" v-if="requestIDCopied"
                      >mdi-check</v-icon
                    >
                    <v-icon color="grey" v-else>mdi-content-copy</v-icon>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-divider></v-divider>
        <p class="red--text text-center mt-3 mb-0 font-weight-bold">
          * Please ensure you use the payment reference
          {{ $store.state.requestID }} or your payment will be returned.
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "bankDetails",
  data() {
    return {
      accountNameCopied: false,
      accountNoCopied: false,
      sortCodeCopied: false,
      requestIDCopied: false,
    };
  },
  methods: {
    copyAccountName() {
      const str = "XYZBankAccount";
      this.copyStringToClipboard(str);
      const dataRef = "accountNameCopied";
      this.toggleIcon(dataRef);
    },
    copyAccountNo() {
      const str = "555555555";
      this.copyStringToClipboard(str);
      const dataRef = "accountNoCopied";
      this.toggleIcon(dataRef);
    },
    copySortCode() {
      const str = "99-999-99";
      this.copyStringToClipboard(str);
      const dataRef = "sortCodeCopied";
      this.toggleIcon(dataRef);
    },
    copyRequestID() {
      const str = this.$store.state.requestID;
      this.copyStringToClipboard(str);
      const dataRef = "requestIDCopied";
      this.toggleIcon(dataRef);
    },
    toggleIcon(selectTarget) {
      setTimeout(() => {
        this[selectTarget] = true;
      }, 100);
      setTimeout(() => {
        this[selectTarget] = false;
      }, 2000);
    },
    copyStringToClipboard(str) {
      // Create new element
      var el = document.createElement("textarea");
      // Set value (string to be copied)
      el.value = str;
      // Set non-editable to avoid focus and move outside of view
      el.setAttribute("readonly", "");
      el.style = { position: "absolute", left: "-9999px" };
      document.body.appendChild(el);
      // Select text inside element
      el.select();
      // Copy text to clipboard
      document.execCommand("copy");
      // Remove temporary element
      document.body.removeChild(el);
    },
  },
};
</script>

<style>
</style>