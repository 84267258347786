<template>
  <div>
    <div class="d-flex justify-center mb-3 mt-8">
      <v-btn
        @click="rerouteHome"
        class=""
        x-large
        depressed
        outlined
        color="primary"
        width="70%"
        >Try again</v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'tryAgainButton',
  methods: {
    rerouteHome() {
      this.$store.commit('CLEAR_STORE')
      if (this.$store.state.genericRequestID) this.$router.push({ path: `/?requestid=${this.$store.state.genericRequestID}` })
      else this.$router.push({ path: `/?requestid=${this.$store.state.requestID}` })
    },
  }
}
</script>

<style>
</style>